import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import { AuthLogout } from "../../actions";

import { Puff } from "react-loading-icons";

const initialState = {
  search: "",
  isAdmin: "",
  isReady: false,
};

class LayoutContainer extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.logOut = this.logOut.bind(this);
    this.handleBrand = this.handleBrand.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      let auth = localStorage.getItem("auth");
      let isAdmin = auth == 1;

      this.setState({ isAdmin, isReady: true });
    }, 500);
  }

  handleBrand = (e, url) => {
    e.preventDefault();

    this.props.history.push(url);
  };

  handleClick = (e) => {
    e.preventDefault();

    let query = this.state.search;

    if (!query) return alert("Please fill the search input...");

    Promise.resolve().then(() => {
      let url = `/view/${query}`;

      this.props.history.push(url);

      this.setState({ search: "" });
    });
  };

  handleChange = (e) => {
    e.preventDefault();

    const target = e.target;
    const name = target.name;
    const value = target.value;

    let params = {
      [name]: value,
    };

    this.setState(params);
  };

  logOut = () => {
    this.props.AuthLogout();

    setTimeout(() => {
      const { history } = this.props;

      history.push("login");
    }, 500);
  };

  render() {
    const { isAuth } = this.props;
    const { search, isReady, isAdmin = false } = this.state;

    if (!isReady) {
      return (
        <div className="loading-container fullsize">
          <Puff stroke="#000" strokeOpacity={0.5} speed={0.75} />
          <p>Loading...</p>
        </div>
      );
    }

    return (
      <>
        <div className="layout-container">
          <div className="header-container">
            <div className="brand">
              <a
                href="/dashboard"
                onClick={(e) => this.handleBrand(e, "/dashboard")}
              >
                Furaidon
              </a>
            </div>

            <div className="action-buttons action-btn-desktop">
              <input
                name="search"
                type="text"
                placeholder="Search..."
                value={search}
                onChange={this.handleChange}
              />
              <button onClick={this.handleClick}>Search</button>
            </div>
            <div className="uttility-buttons">
              {isAuth && isAdmin && (
                <button onClick={(e) => this.handleBrand(e, "/administrasi")}>
                  Settings
                </button>
              )}
              {isAuth && <button onClick={this.logOut}>Logout</button>}
            </div>
          </div>

          <div className="action-buttons action-btn-mobile">
            <input
              name="search"
              type="text"
              placeholder="Search..."
              value={search}
              onChange={this.handleChange}
            />
            <button onClick={this.handleClick}>Search</button>
          </div>

          {this.props.children}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuth,
});

const mapDispatchToProps = (dispatch) => ({
  AuthLogout: () => {
    dispatch(AuthLogout());
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(LayoutContainer);
