import {
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILED,
} from "../actions/types";

const initialState = {
  loading: false,
  success: false,
  message: "",
  data: [],
};

const Users = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_USERS_SUCCESS:
      var { success, message, data } = action.payload;
      var newState = {
        loading: false,
        success,
        message,
        data,
      };

      return { ...state, ...newState };

    case GET_USERS_FAILED:
      var { success, message } = action.payload;

      var newState = {
        loading: false,
        success,
        message,
      };

      return { ...state, ...newState };

    default:
      return state;
  }
};

export default Users;
