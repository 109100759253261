import {
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILED,
} from "../actions/types";

const initialState = {
  loading: false,
  success: false,
  message: "",
  data: [],
};

const User = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_USER_SUCCESS:
      var { success, message, data } = action.payload;
      var newState = {
        loading: false,
        success,
        message,
        data,
      };

      return { ...state, ...newState };

    case GET_USER_FAILED:
      var { success, message } = action.payload;

      var newState = {
        loading: false,
        success,
        message,
      };

      return { ...state, ...newState };

    default:
      return state;
  }
};

export default User;
