import axiosInstance from "../utils/axiosInstance";

import { DELETE_URL } from "./constant";

export const deleteApi = async (params) => {
  let queryUrl = `${DELETE_URL}`;

  const { file_url, parent_id, short_url } = params;

  let data = new FormData();
  let target = parent_id != null ? parent_id : short_url;

  data.append("file", file_url);
  data.append("shortUrl", target);

  let overrideConfig = {
    headers: {
      // "Content-Type": "multipart/form-data",
    },
    url: queryUrl,
    method: "post",
    data,
  };

  let axios = axiosInstance(overrideConfig);

  return axios;
};
