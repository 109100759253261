import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import { sliceArray } from "../../utils/helpers";

import { UploadActions } from "../../actions/upload";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { toast } from "react-toastify";
import { Puff } from "react-loading-icons";
import { FiCopy } from "react-icons/fi";

import Swal from "sweetalert2";

const initialState = {};

class SubCategoriesContainer extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.uploadFiles = this.uploadFiles.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleExtend = this.handleExtend.bind(this);
    this.handleCancelAll = this.handleCancelAll.bind(this);
    this.handleUploadAll = this.handleUploadAll.bind(this);
    this.ifAnyLoading = this.ifAnyLoading.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      const { SubCategories } = this.props;

      let arrs = {
        categories: [],
      };

      SubCategories.map((item) => {
        var obj = {
          [item.slug]: {
            id: item.id,
            name: item.name,
            slug: item.slug,
            alias: item.alias,
            imageFile: "",
            imagePath: "",
            parentLink: "",
            isLoading: false,
            isExtend: false,
            statusCode: item.statusCode,
            message: item.message,
          },
        };

        arrs.categories.push(obj);
      });

      this.setState({ categories: arrs.categories });
    }, 200);
  }

  handleChange = (e) => {
    e.preventDefault();

    let target = e.target,
      name = target.getAttribute("name"),
      newState = {
        imageFile: target.files[0],
        imagePath: target.value,
        statusCode: "",
        message: "",
        isLoading: false,
      };

    this.processState(name, newState);
    setTimeout(() => {
      this.ifAnyLoading();
    }, 1000);
  };

  handleFileChange = (e) => {
    e.preventDefault();

    let target = e.target,
      name = target.getAttribute("name"),
      value = target.value,
      newState = { file: value, statusCode: "", message: "" };

    this.processState(name, newState);
  };

  handleCancel = (e, isLoading) => {
    e.preventDefault();

    if (isLoading) return;

    let target = e.target,
      name = target.getAttribute("name"),
      newState = {
        imageFile: "",
        imagePath: "",
        statusCode: "",
        message: "",
      };

    this.processState(name, newState);
  };

  handleCancelAll = (e) => {
    if (this.ifAnyLoading()) return;

    e.preventDefault();

    let newFinalState = Object(this.state.categories).map((items) => {
      let key = Object.keys(items)[0];

      return items[key];
    });

    let arrs = { categories: [] };

    newFinalState.map((item) => {
      var obj = {
        [item.slug]: {
          id: item.id,
          name: item.name,
          slug: item.slug,
          alias: item.alias,
          imageFile: "",
          imagePath: "",
          parentLink: "",
          isLoading: false,
          isExtend: false,
          statusCode: "",
          message: "",
        },
      };

      arrs.categories.push(obj);
    });

    this.setState({ categories: arrs.categories });
  };

  handleExtend = (e) => {
    let target = e.target,
      name = target.getAttribute("name"),
      newState = { isExtend: target.checked };

    this.processState(name, newState);
  };

  handleParentLink = (e) => {
    let target = e.target,
      name = target.getAttribute("name"),
      value = target.value,
      newState = { parentLink: value };

    this.processState(name, newState);
  };

  processState = (name, newState) => {
    let currentState = { ...this.state.categories };

    let currentIndex;
    let filteredState = Object.values(currentState).filter((item, index) => {
      if (Object.keys(item) == name) {
        currentIndex = index;

        return item;
      }
    })[0][name];

    let newFinalState = {
      [currentIndex]: {
        [name]: { ...filteredState, ...newState },
      },
    };

    let finalState = { ...currentState, ...newFinalState };

    this.setState({ categories: Object.values(finalState) });
  };

  uploadFiles = (item) => {
    let name = item.slug;

    Promise.resolve()
      .then(() => this.processState(name, { isLoading: true }))
      .then(async () => {
        if (!item.imageFile) {
          this.processState(name, {
            isLoading: false,
            imageFile: "",
            imagePath: "",
          });

          return;
        }

        let items = Object.assign(
          {
            ownerName: this.props.auth.name,
            ownerImg: this.props.auth.imageUrl,
            date: this.props.date,
            category: this.props.category,
          },
          item
        );

        const { UploadActions } = this.props;

        await UploadActions(items).then(() => {
          let currentState = { ...this.props.SubCategories };
          let filteredState = Object.values(currentState).filter((item) => {
            if (item.slug == name) return item;
          })[0];

          this.processState(name, {
            isLoading: false,
            isExtend: false,
            parentLink: "",
            imageFile: "",
            imagePath: "",
            statusCode: filteredState.statusCode,
            message: filteredState.message,
          });

          toast.success(`${item.name}  ${filteredState.message.code}`);
        });
      });
  };

  handleUploadAll = () => {
    if (this.ifAnyLoading()) return;

    const { categories: cat } = this.state;

    let SubCategories = Object(cat).map((items) => {
      let key = Object.keys(items)[0];

      return items[key];
    });

    SubCategories.map((item) => this.uploadFiles(item));
  };

  ifAnyLoading = () => {
    const { categories: cat } = this.state;

    let SubCategories = Object(cat).map((items) => {
      let key = Object.keys(items)[0];

      return items[key];
    });

    let filterIsLoading = Object.values(SubCategories).filter((item) => {
      if (item.isLoading == true) return item;
    });

    return filterIsLoading.length > 0;
  };

  render() {
    const { categories: cat } = this.state;

    if (!cat)
      return (
        <div className="loading-container fullsize">
          <Puff stroke="#000" strokeOpacity={0.5} speed={0.75} />
          <p>Loading...</p>
        </div>
      );

    let SubCategories = Object(cat).map((items) => {
      let key = Object.keys(items)[0];

      return items[key];
    });

    const categories = sliceArray(SubCategories);

    return (
      <>
        <div className="subcategories-action-wrapper">
          {this.ifAnyLoading() !== true ? (
            <>
              <button className="btn btn-info" onClick={this.handleUploadAll}>
                Upload All
              </button>
              <button
                className="btn btn-warning"
                onClick={this.handleCancelAll}
              >
                Cancel All
              </button>
            </>
          ) : (
            <>
              <button
                className="btn btn-secondary"
                style={{
                  cursor: "not-allowed",
                }}
              >
                Upload All
              </button>
              <button
                className="btn btn-secondary"
                style={{
                  cursor: "not-allowed",
                }}
              >
                Cancel All
              </button>
            </>
          )}
        </div>

        <div className="subcategories-wrapper">
          <div className="subcategories-container">
            {!categories && (
              <div className="loading-container fullsize">
                <Puff stroke="#000" strokeOpacity={0.5} speed={0.75} />
                <p>Loading...</p>
              </div>
            )}

            {categories.map((category, cIndex) => {
              return (
                <div key={cIndex} className="subcategories-section">
                  {category.map((item, index) => {
                    return (
                      <div key={index} className="subcategories-item">
                        <div className="title">{item.name}</div>
                        <div className="input">
                          <input
                            name={item.slug}
                            type="file"
                            value={item.imagePath}
                            onChange={this.handleChange}
                            disabled={item.isLoading}
                          />

                          {item.imageFile ? (
                            <div
                              className="close"
                              name={item.slug}
                              onClick={(e) =>
                                this.handleCancel(e, item.isLoading)
                              }
                              style={{
                                cursor: item.isLoading
                                  ? "not-allowed"
                                  : "pointer",
                              }}
                            >
                              X
                            </div>
                          ) : null}
                          <br />
                          <br />

                          {item.isLoading == true ? (
                            <div className="loading-container">
                              <Puff
                                stroke="#000"
                                strokeOpacity={0.5}
                                speed={0.75}
                              />
                              <p>Loading...</p>
                            </div>
                          ) : (
                            <>
                              <div className="button-container">
                                <button
                                  className={`btn ${
                                    item.imageFile
                                      ? "btn-info"
                                      : "btn-secondary"
                                  }`}
                                  onClick={() =>
                                    item.imageFile
                                      ? this.uploadFiles(item)
                                      : null
                                  }
                                  style={{
                                    cursor: item.imageFile
                                      ? "pointer"
                                      : "not-allowed",
                                  }}
                                >
                                  Upload
                                </button>

                                <div className="input-group">
                                  <input
                                    id={`extend${item.id}`}
                                    name={item.slug}
                                    type="checkbox"
                                    className="extend-input"
                                    value={item.isExtend}
                                    onChange={this.handleExtend}
                                  />
                                  <label htmlFor={`extend${item.id}`}>
                                    Is Extend?
                                  </label>
                                </div>
                              </div>

                              {item.isExtend ? (
                                <div className="extend-container">
                                  <input
                                    name={item.slug}
                                    type="text"
                                    onChange={this.handleParentLink}
                                    placeholder="Extend Code..."
                                  />
                                </div>
                              ) : null}
                            </>
                          )}

                          {item.statusCode == "ok" ? (
                            <>
                              <br />
                              <div className="text-info">
                                <p>Extend Code: {item.message.code}</p>

                                <div className="permalink">
                                  <span>{item.message.permalink}</span>

                                  <CopyToClipboard
                                    text={item.message.permalink}
                                    onCopy={() => toast.info(`Text Copied`)}
                                  >
                                    <span className="copy-btn" title="copy">
                                      <FiCopy />
                                    </span>
                                  </CopyToClipboard>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {item.statusCode == "error" ? (
                            <>
                              <br />
                              <div className="text-danger">
                                Message : {item.message}
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.data,
    date: state.date.date,
    SubCategories: state.SubCategories.data,
  };
};
const mapDispatchToProps = (dispatch) => ({
  UploadActions: (params) => {
    return new Promise((resolve) => {
      dispatch(UploadActions(params)).then(() => resolve());
    });
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SubCategoriesContainer);
