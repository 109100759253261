import LoginContainer from "../containers/AuthContainer/Login";
import SearchContainer from "../containers/SearchContainer";

const PublicRoutes = [
  {
    path: "/search/:query",
    component: SearchContainer,
    exact: true,
  },
  {
    path: "/view/:query",
    component: SearchContainer,
    exact: true,
  },
  {
    path: "/login",
    component: LoginContainer,
    restricted: true,
    exact: true,
  },
  {
    path: "/",
    component: LoginContainer,
    exact: true,
  },
];

export default PublicRoutes;
