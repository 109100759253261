import { SEARCH_REQUEST, SEARCH_SUCCESS, SEARCH_FAILED } from "./types";

import { searchApi } from "../api/search";

import errorHandler from "../utils/errHandler";

export const SearchActions = (params) => async (dispatch) => {
  dispatch(SearchActionRequest());

  await searchApi(params)
    .then((response) => {
      const { data } = response;

      if (data.status == "ok") {
        dispatch(SearchActionSuccess(data));
      } else {
        dispatch(SearchActionFailed(data));
      }
    })
    .catch((error) => {
      console.log("error : ", error);

      errorHandler(error);
    });
};

export const SearchActionRequest = () => {
  return {
    type: SEARCH_REQUEST,
  };
};

export const SearchActionSuccess = (result) => {
  return {
    type: SEARCH_SUCCESS,
    payload: {
      result,
    },
  };
};

export const SearchActionFailed = (result) => {
  return {
    type: SEARCH_FAILED,
    payload: {
      result,
    },
  };
};
