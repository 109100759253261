import {
  GET_SUBCATEGORIES_REQUEST,
  GET_SUBCATEGORIES_SUCCESS,
  GET_SUBCATEGORIES_FAILED,
  RESET_SUBCATEGORIES,
  UPLOAD_SUCCESS,
  UPLOAD_FAILED,
} from "../actions/types";

const initialState = {
  loading: false,
  success: false,
  message: "",
  data: [],
};

const SubCategories = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBCATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_SUBCATEGORIES_SUCCESS:
      var { success, message, data } = action.payload;

      var newState = {
        loading: false,
        success,
        message,
        data,
      };

      return { ...state, ...newState };

    case GET_SUBCATEGORIES_FAILED:
      var { success, message } = action.payload;

      var newState = {
        loading: false,
        success,
        message,
      };

      return { ...state, ...newState };

    case RESET_SUBCATEGORIES:
      return initialState;

    case UPLOAD_SUCCESS:
    case UPLOAD_FAILED:
      var { result, data: dataItems } = action.payload;

      var { slug } = result;

      var { data: currentItems } = state;

      var selected = currentItems.filter((item) => item.slug == slug);

      var newObj = { statusCode: dataItems.status, message: dataItems.message };

      Object.assign(...selected, newObj);

      return state;

    default:
      return state;
  }
};

export default SubCategories;
