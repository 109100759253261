import {
  GET_SUBCATEGORIES_REQUEST,
  GET_SUBCATEGORIES_SUCCESS,
  GET_SUBCATEGORIES_FAILED,
  RESET_SUBCATEGORIES,
} from "./types";

import { categoriesApi } from "../api/categories";

import errorHandler from "../utils/errHandler";

export const GetSubCategories = (categoryId) => async (dispatch) => {
  dispatch(GetSubCategoriesRequest());

  await categoriesApi(categoryId)
    .then((response) => response.data)
    .then((result) => {
      if (result.success === true) {
        dispatch(GetSubCategoriesSuccess(result));
      } else {
        dispatch(GetSubCategoriesFailed(result));
      }
    })
    .catch((error) => {
      console.log("error : ", error);

      errorHandler(error);
    });
};

export const GetSubCategoriesRequest = () => {
  return {
    type: GET_SUBCATEGORIES_REQUEST,
  };
};

export const GetSubCategoriesSuccess = (result) => {
  return {
    type: GET_SUBCATEGORIES_SUCCESS,
    payload: result,
  };
};

export const GetSubCategoriesFailed = (result) => {
  return {
    type: GET_SUBCATEGORIES_FAILED,
    payload: result,
  };
};

export const ResetSubCategories = () => {
  return {
    type: RESET_SUBCATEGORIES,
  };
};
