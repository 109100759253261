import {
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILED,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILED,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILED,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILED,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED,
} from "./types";

import {
  getUserApi,
  getUsersApi,
  createUserApi,
  deleteUserApi,
} from "../api/users";

export const GetUser = (userId) => async (dispatch) => {
  dispatch(GetUserRequest());

  await getUserApi(userId)
    .then((response) => response.data)
    .then((result) => {
      if (result.status == "ok") {
        dispatch(GetUserSuccess(result));
      } else {
        dispatch(GetUserFailed(result));
      }
    });
};

export const GetUserRequest = () => {
  return {
    type: GET_USER_REQUEST,
  };
};

export const GetUserSuccess = (result) => {
  return {
    type: GET_USER_SUCCESS,
    payload: result,
  };
};

export const GetUserFailed = (result) => {
  return {
    type: GET_USER_FAILED,
    payload: result,
  };
};

export const GetUsers = () => async (dispatch) => {
  dispatch(GetUsersRequest());

  await getUsersApi()
    .then((response) => response.data)
    .then((result) => {
      if (result.status == "ok") {
        dispatch(GetUsersSuccess(result));
      } else {
        dispatch(GetUsersFailed(result));
      }
    });
};

export const GetUsersRequest = () => {
  return {
    type: GET_USERS_REQUEST,
  };
};

export const GetUsersSuccess = (result) => {
  return {
    type: GET_USERS_SUCCESS,
    payload: result,
  };
};

export const GetUsersFailed = (result) => {
  return {
    type: GET_USERS_FAILED,
    payload: result,
  };
};

export const CreateUser = (params) => async (dispatch) => {
  await createUserApi(params)
    .then((response) => response.data)
    .then((result) => {
      if (result.status == "ok") {
        dispatch(CreateUserSuccess(result));
      } else {
        dispatch(CreateUserFailed(result));
      }
    });
};

export const CreateUserRequest = () => {
  return {
    type: CREATE_USER_REQUEST,
  };
};
export const CreateUserSuccess = (result) => {
  return {
    type: CREATE_USER_SUCCESS,
    payload: result,
  };
};
export const CreateUserFailed = (result) => {
  return {
    type: CREATE_USER_FAILED,
    payload: result,
  };
};

export const DeleteUser = (userId) => async (dispatch) => {
  await deleteUserApi(userId)
    .then((response) => response.data)
    .then((result) => {
      if (result.status == "ok") {
        dispatch(DeleteUserSuccess(result));
      } else {
        dispatch(DeleteUserFailed(result));
      }
    });
};

export const DeleteUserRequest = () => {
  return {
    type: DELETE_USER_REQUEST,
  };
};
export const DeleteUserSuccess = (result) => {
  return {
    type: DELETE_USER_SUCCESS,
    payload: result,
  };
};
export const DeleteUserFailed = (result) => {
  return {
    type: DELETE_USER_FAILED,
    payload: result,
  };
};
