import {
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SEARCH_FAILED,
} from "../actions/types";

const initialState = {
  isLoading: false,
  shortUrl: "",
  links: "",
};

const Search = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_REQUEST:
      var newState = {
        isLoading: true,
        shortUrl: "",
        links: "",
      };

      return { ...state, ...newState };

    case SEARCH_SUCCESS:
      var { result } = action.payload;
      var { data } = result;
      var { links, short_encrypted } = data;

      var newState = {
        isLoading: false,
        shortUrl: short_encrypted,
        links,
      };

      return { ...state, ...newState };

    case SEARCH_FAILED:
      var newState = {
        isLoading: false,
        shortUrl: "",
        links: "",
      };

      return { ...state, ...newState };

    default:
      return state;
  }
};

export default Search;
