import { UPLOAD_REQUEST, UPLOAD_SUCCESS, UPLOAD_FAILED } from "./types";

import { uploadApi } from "../api/upload";

import errorHandler from "../utils/errHandler";

export const UploadActions = (params) => async (dispatch) => {
  dispatch(UploadActionRequest());

  await uploadApi(params)
    .then((response) => {
      const { data } = response;

      if (data.status == "ok") {
        dispatch(UploadActionSuccess(params, data));
      } else {
        dispatch(UploadActionFailed(params, data));
      }
    })
    .catch((error) => {
      console.log("error : ", error);

      errorHandler(error);
    });
};

export const UploadActionRequest = () => {
  return {
    type: UPLOAD_REQUEST,
  };
};

export const UploadActionSuccess = (result, data) => {
  return {
    type: UPLOAD_SUCCESS,
    payload: {
      result,
      data,
    },
  };
};

export const UploadActionFailed = (result, data) => {
  return {
    type: UPLOAD_FAILED,
    payload: {
      result,
      data,
    },
  };
};
