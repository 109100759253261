import {
  CLEAR_LOADING_STATE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
} from "../actions/types";

const initialState = {
  isAuth: false,
  loading: false,
  data: [],
};

const Auth = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_LOADING_STATE:
      return { ...state, loading: false };

    case LOGIN_REQUEST:
      return { ...state, loading: true };

    case LOGIN_SUCCESS:
      var { data } = action.payload;
      var { token, permission_key } = data;

      var newState = {
        isAuth: token != null,
        loading: false,
      };

      localStorage.setItem("token", token);
      localStorage.setItem("auth", permission_key);

      return { ...state, ...newState };

    case LOGOUT:
      localStorage.removeItem("token");
      localStorage.removeItem("auth");

      return { ...state, isAuth: false, data: [] };

    default:
      return state;
  }
};

export default Auth;
