import React, { Component } from "react";

import Calendar from "react-calendar";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import { SetDate, ResetDate } from "../../actions";

import { formatDate } from "../../utils/helpers";

class DashboardContainer extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleResetDate = this.handleResetDate.bind(this);
  }

  componentDidMount() {
    const { ResetDate, date } = this.props;

    setTimeout(() => {
      if (!date) ResetDate();
    }, 2000);
  }

  handleClick = (value) => {
    const { history, SetDate } = this.props;

    Promise.resolve()
      .then(() => {
        let formatted = formatDate(value);

        SetDate(formatted);

        return formatted;
      })
      .then((value) => history.push(`/categories/${value}`));
  };

  handleResetDate = () => {
    const { SetDate } = this.props;

    let date = new Date();
    let today = formatDate(date);

    SetDate(today);
  };

  render() {
    const { date } = this.props;
    const selected = new Date(date);

    return (
      <>
        <div className="dashboard-upper">
          <div className="unit-item">Selected Date : {date}</div>
          <div className="unit-item reset-btn" onClick={this.handleResetDate}>
            Reset Date
          </div>
        </div>
        <div className="dashboard-container">
          <Calendar
            onClickDay={this.handleClick}
            defaultValue={selected}
            value={selected}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  date: state.date.date,
});

const mapDispatchToProps = (dispatch) => ({
  SetDate: (value) => {
    dispatch(SetDate(value));
  },
  ResetDate: (value) => {
    dispatch(ResetDate(value));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(DashboardContainer);
