import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import { GetUsers, DeleteUser } from "../../actions/users";

import { Puff } from "react-loading-icons";

import Swal from "sweetalert2";

let initalState = {};

class AdminContainer extends Component {
  constructor(props) {
    super(props);

    this.state = initalState;

    this.fetchUsers = this.fetchUsers.bind(this);
    this.handleDelete = this.handleDelete.bind(this);

    this.processDelete = this.processDelete.bind(this);
    this.handleNavigation = this.handleNavigation.bind(this);
  }

  componentDidMount() {
    let auth = localStorage.getItem("auth");
    let isAdmin = auth == 1;

    if (!isAdmin) {
      const { history } = this.props;

      history.push("/");
    }

    this.fetchUsers();
  }

  handleNavigation = (e, link) => {
    e.preventDefault();

    const { history } = this.props;

    history.push(link);
  };

  fetchUsers = () => {
    const { GetUsers } = this.props;

    GetUsers();
  };

  processDelete = (userId) => {
    const { DeleteUser } = this.props;

    DeleteUser(userId);
  };

  handleDelete = (userId) => {
    Swal.fire({
      title: "Warning!",
      text: "Do you really want to Delete this file?",
      icon: "warning",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
    }).then((result) => {
      if (result.isConfirmed) {
        Promise.resolve()
          .then(() => this.processDelete(userId))
          .then(() => this.fetchUsers());
      }
    });
  };

  render() {
    const { users } = this.props;

    if (!users) {
      return (
        <div className="loading-container fullsize">
          <Puff stroke="#000" strokeOpacity={0.5} speed={0.75} />
          <p>Loading...</p>
        </div>
      );
    }

    return (
      <div
        id="admin-container"
        className="h-100 d-flex align-items-center justify-content-center"
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 offset-lg-3">
              <h2 className="text-center mb-3">User List</h2>
              <span className="add-actions">
                <button
                  className="btn btn-info"
                  onClick={(e) =>
                    this.handleNavigation(e, "/administrasi/create")
                  }
                >
                  + Add User
                </button>
              </span>

              <table id="user-table" width="100%" border="1">
                <thead>
                  <tr>
                    <td>Username</td>
                    <td>Role</td>
                    <td align="center">Actions</td>
                  </tr>
                </thead>
                <tbody>
                  {users &&
                    users.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.username}</td>
                          <td>{item.role_name}</td>

                          {item.role_id != 1 && (
                            <td align="center">
                              <span className="action-btn">
                                <button
                                  className="btn btn-warning"
                                  onClick={(e) =>
                                    this.handleNavigation(
                                      e,
                                      `/administrasi/create/${item.id}`
                                    )
                                  }
                                >
                                  Edit
                                </button>
                              </span>
                              <span className="action-btn">
                                <button
                                  className="btn btn-danger"
                                  onClick={() => this.handleDelete(item.id)}
                                >
                                  Delete
                                </button>
                              </span>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.Users.data,
    user: state.User.data,
  };
};
const mapDispatchToProps = (dispatch) => ({
  GetUsers: () => {
    dispatch(GetUsers());
  },
  DeleteUser: (userId) => {
    dispatch(DeleteUser(userId));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AdminContainer);
