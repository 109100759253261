import DashboardContainer from "../containers/DashboardContainer";
import CategoryContainer from "../containers/CategoryContainer";
import UploadContainer from "../containers/UploadContainer";
import AdminContainer from "../containers/AdminContainer";
import AdminCreate from "../containers/AdminCreateContainer";

const PrivateRoutes = [
  {
    path: "/upload/:categoryId",
    component: UploadContainer,
    exact: true,
  },
  {
    path: "/categories/:categoryId",
    component: CategoryContainer,
    exact: true,
  },
  {
    path: "/administrasi/create",
    component: AdminCreate,
    exact: true,
  },
  {
    path: "/administrasi",
    component: AdminContainer,
    exact: true,
  },
  {
    path: "/administrasi/create/:userId",
    component: AdminCreate,
    exact: true,
  },
  {
    path: "/dashboard",
    component: DashboardContainer,
    exact: true,
  },
];

export default PrivateRoutes;
