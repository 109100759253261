import axiosInstance from "../utils/axiosInstance";

import {
  GET_USER_URL,
  GET_USERS_URL,
  CREATE_USER_URL,
  DELETE_USER_URL,
} from "./constant";

export const getUsersApi = async () => {
  let queryUrl = `${GET_USERS_URL}`;

  return await axiosInstance.get(queryUrl);
};

export const getUserApi = async (userId) => {
  let queryUrl = `${GET_USER_URL}/${userId}`;

  return await axiosInstance.get(queryUrl);
};

export const createUserApi = async (params) => {
  let queryUrl = CREATE_USER_URL;

  const { user_id, username, password, role } = params;

  let data = new FormData();

  if (user_id) {
    data.append("user_id", user_id);
  }

  data.append("username", username);
  data.append("password", password);
  data.append("role", role);

  let overrideConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    url: queryUrl,
    method: "post",
    data,
  };

  let axios = axiosInstance(overrideConfig);

  return axios;
};

export const deleteUserApi = async (user_id) => {
  let queryUrl = `${DELETE_USER_URL}/${user_id}`;

  let overrideConfig = {
    url: queryUrl,
    method: "Delete",
  };

  let axios = axiosInstance(overrideConfig);

  return axios;
};
