import axiosInstance from "../utils/axiosInstance";

import { UPLOAD_URL } from "./constant";

export const uploadApi = async (params) => {
  let uploadUrl = `${UPLOAD_URL}`;

  const {
    date,
    category,
    alias,
    imageFile,
    imagePath,
    name,
    slug,
    stats,
    ownerName,
    ownerImg,
    parentLink,
    isExtend,
  } = params;

  let data = new FormData();

  data.append("name", name);
  data.append("slug", slug);
  data.append("date", date);
  data.append("stats", stats);
  data.append("alias", alias);

  data.append("category", category);
  data.append("isExtend", isExtend);
  data.append("imageFile", imageFile);
  data.append("imagePath", imagePath);

  data.append("ownerName", ownerName);
  data.append("ownerImg", ownerImg);

  data.append("parentLink", parentLink);

  let overrideConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    url: uploadUrl,
    method: "post",
    data,
  };

  let axios = axiosInstance(overrideConfig);

  return axios;
};
