import {
  CLEAR_LOADING_STATE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGIN_RESET_MESSAGE,
  LOGOUT,
} from "./types";

import { loginApi } from "../api/auth";

import errorHandler from "../utils/errHandler";

export const AuthLogin = (username, password) => async (dispatch) => {
  dispatch(AuthLoginRequest());

  await loginApi(username, password)
    .then((response) => response.data)
    .then((result) => {
      if (result.success === true) {
        dispatch(AuthLoginSuccess(result));
      } else {
        dispatch(AuthLoginFailed(result));
      }
    })
    .catch((error) => {
      console.log("error : ", error);

      errorHandler(error);
    });
};

export const AuthLoginRequest = () => {
  return {
    type: LOGIN_REQUEST,
  };
};

export const AuthLoginSuccess = (result) => {
  return {
    type: LOGIN_SUCCESS,
    payload: result,
  };
};

export const AuthLoginFailed = (result) => {
  return {
    type: LOGIN_FAILED,
    payload: result,
  };
};

export const AuthResetMessage = () => {
  return {
    type: LOGIN_RESET_MESSAGE,
  };
};

export const AuthLogout = () => {
  return {
    type: LOGOUT,
  };
};

export const ClearLoadingStates = () => {
  return {
    type: CLEAR_LOADING_STATE,
  };
};
