import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import { Puff } from "react-loading-icons";

import Swal from "sweetalert2";

import { SearchActions, DeleteActions } from "../../actions";

const initialState = {
  isAdmin: "",
  isReady: false,
};

class SearchContainer extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.handleDelete = this.handleDelete.bind(this);
    this.searchQuery = this.searchQuery.bind(this);
    this.handleNavigation = this.handleNavigation.bind(this);
  }

  componentDidMount() {
    this.searchQuery();

    setTimeout(() => {
      let auth = localStorage.getItem("auth");
      let isAdmin = auth == 1;

      this.setState({ isAdmin, isReady: true });
    }, 500);
  }

  handleNavigation = (e, link) => {
    e.preventDefault();

    const { history } = this.props;

    history.push(link);
  };

  searchQuery = () => {
    Promise.resolve().then(async () => {
      const { match } = this.props;
      const { params } = match;

      const { query } = params;

      const { SearchActions } = this.props;

      SearchActions(query);
    });
  };

  handleDelete = (e, params) => {
    e.preventDefault();

    Swal.fire({
      title: "Warning!",
      text: "Do you really want to Delete this file?",
      icon: "warning",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const { DeleteActions } = this.props;

        DeleteActions(params)
          .then(() => this.searchQuery())
          .then(() => {});
      }
    });
  };

  render() {
    const { search } = this.props;

    const { isLoading, shortUrl, links } = search;

    const { isReady, isAdmin = false } = this.state;

    if (!isReady) {
      return (
        <div className="loading-container fullsize">
          <Puff stroke="#000" strokeOpacity={0.5} speed={0.75} />
          <p>Loading...</p>
        </div>
      );
    }

    return (
      <div className="search-result-container">
        <h2>Search Result for {shortUrl}</h2>

        {isLoading && (
          <div className="loading-container">
            <Puff stroke="#000" strokeOpacity={0.5} speed={0.75} />
            <p>Loading...</p>
          </div>
        )}

        {!isLoading && !links && (
          <div style={{ textAlign: "center" }}>
            <div>Nothing found...</div>
            <br />
            <div>
              <a href="#" onClick={(e) => this.handleNavigation(e, "/")}>
                Back to dashboard
              </a>
            </div>
          </div>
        )}

        <table width="100%" border="1">
          {links &&
            links.map((item, index) => {
              return (
                <tbody key={index}>
                  <tr>
                    <td>
                      <a target="_blank" href={item.file_url}>
                        {item.file_url}
                      </a>
                    </td>
                    {isAdmin && (
                      <td>
                        <button
                          className="btn btn-danger"
                          onClick={(e) => this.handleDelete(e, item)}
                        >
                          Delete
                        </button>
                      </td>
                    )}
                  </tr>
                </tbody>
              );
            })}
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  search: state.search,
});

const mapDispatchToProps = (dispatch) => ({
  SearchActions: (query) => {
    return new Promise((resolve) => {
      dispatch(SearchActions(query)).then(() => resolve());
    });
  },
  DeleteActions: (params) => {
    return new Promise((resolve) => {
      dispatch(DeleteActions(params)).then(() => resolve());
    });
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(SearchContainer);
