import React, { Component, Suspense } from "react";

import {
  BrowserRouter as Router,
  withRouter,
  Redirect,
  Switch,
} from "react-router-dom";

import { ToastContainer } from "react-toastify";
import { connect } from "react-redux";
import { compose } from "redux";

import LayoutContainer from "./containers/LayoutContainer";

import { PublicRoute, PrivateRoute } from "./components/RouteState";
import { PublicRoutes, PrivateRoutes } from "./routes";

import "./styles/app.scss";
import "react-toastify/dist/ReactToastify.css";

class App extends Component {
  render() {
    return (
      <>
        <Router>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <LayoutContainer>
                <ToastContainer
                  className="toast-container"
                  bodyClassName="toast-body"
                  progressClassName="toast-progress-bar"
                  position="bottom-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnVisibilityChange
                  draggable
                  pauseOnHover
                />

                {PublicRoutes.map((route, index) => {
                  return (
                    <PublicRoute
                      key={index}
                      path={route.path}
                      component={route.component}
                      exact={route.exact}
                      restricted={route.restricted}
                    />
                  );
                })}

                {PrivateRoutes.map((route, index) => {
                  return (
                    <PrivateRoute
                      key={index}
                      path={route.path}
                      component={route.component}
                      exact={route.exact}
                    />
                  );
                })}
              </LayoutContainer>

              <Redirect to={"/"} />
            </Switch>
          </Suspense>
        </Router>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(App);
