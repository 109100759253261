import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import { Puff } from "react-loading-icons";
import { arrs } from "../../utils/data";

const SubCategoriesContainer = React.lazy(() =>
  import("../SubCategoriesContainer")
);

import { GetSubCategories, ResetSubCategories } from "../../actions";

const initialState = {};

class UploadContainer extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.handleNavigation = this.handleNavigation.bind(this);
    this.handleDashboardNav = this.handleDashboardNav.bind(this);
  }

  componentDidMount() {
    const { GetSubCategories, match } = this.props;
    const { params } = match;
    const { categoryId } = params;

    setTimeout(async () => {
      await GetSubCategories(categoryId);
    }, 1000);
  }

  componentWillUnmount() {
    const { ResetSubCategories } = this.props;

    ResetSubCategories();
  }

  handleNavigation = () => {
    const { date, history } = this.props;

    history.push(`/categories/${date}`);
  };

  handleDashboardNav = (e) => {
    e.preventDefault();

    const { history } = this.props;

    history.push(`/dashboard`);
  };

  render() {
    const { date, match, SubCategories } = this.props;
    const { params } = match;
    const { categoryId } = params;

    if (!SubCategories) {
      return <>Loading...</>;
    }

    const result = arrs.filter((arr) => {
      let rArr = arr.filter((item) => item.id == categoryId);

      return rArr.length > 0;
    });

    const rArr2 = result[0]
      ? result[0].filter((item) => {
          return item.id == categoryId;
        })[0]
      : [];

    return (
      <>
        <div className="upload-upper">
          <div className="upload-navigation" onClick={this.handleNavigation}>
            {"< "}Back
          </div>
          <h2>Upload Files - {rArr2.name}</h2>
          <div>
            <a href="/dashboard" onClick={(e) => this.handleDashboardNav(e)}>
              {date}
            </a>
          </div>
        </div>

        <div className="upload-container">
          <div className="upload-wrapper">
            {SubCategories.length > 0 ? (
              <SubCategoriesContainer
                category={rArr2.name}
                categoryId={categoryId}
              />
            ) : (
              <div className="loading-container fullsize">
                <Puff stroke="#000" strokeOpacity={0.5} speed={0.75} />
                <p>Loading...</p>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  date: state.date.date,
  SubCategories: state.SubCategories.data,
});

const mapDispatchToProps = (dispatch) => ({
  GetSubCategories: (categoryId) => {
    dispatch(GetSubCategories(categoryId));
  },
  ResetSubCategories: () => {
    dispatch(ResetSubCategories());
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(UploadContainer);
