import { UPLOAD_REQUEST } from "../actions/types";

const initialState = {
  isLoading: false,
};

const Upload = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_REQUEST:
      var newState = {
        isLoading: true,
      };

      return { ...state, ...newState };

    default:
      return state;
  }
};

export default Upload;
