import axiosInstance from "../utils/axiosInstance";

import { QUERY_URL } from "./constant";

export const searchApi = async (params) => {
  let queryUrl = `${QUERY_URL}`;

  let data = new FormData();

  data.append("code", params);

  let overrideConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    url: queryUrl,
    method: "post",
    data,
  };

  let axios = axiosInstance(overrideConfig);

  return axios;
};
