import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import { GetUser, CreateUser } from "../../actions/users";

let initalState = {
  user_id: "",
  username: "",
  password: "",
  role: 2,
};

class AdminCreate extends Component {
  constructor(props) {
    super(props);

    this.state = initalState;

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNavigation = this.handleNavigation.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    const { userId } = params;

    if (userId) {
      const { GetUser } = this.props;

      GetUser(userId).then(() => {
        const { id, username, password, role_id } = this.props.user;

        let newParams = {
          user_id: id,
          role: role_id,
          username,
          password,
        };

        this.setState(newParams);
      });
    }
  }

  handleNavigation = () => {
    const { history } = this.props;

    history.push("/administrasi");
  };

  handleChange = (e) => {
    e.preventDefault();

    const target = e.target;
    const name = target.name;
    const value = target.value;

    let params = {
      [name]: value,
    };

    this.setState(params);
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { CreateUser } = this.props;

    Promise.resolve()
      .then(() => CreateUser(this.state))
      .then(() => this.handleNavigation());
  };

  render() {
    const { user_id, username, password, role } = this.state;

    return (
      <div
        id="admin-create"
        className="h-100 d-flex align-items-center justify-content-center"
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 offset-lg-3">
              <h2 className="text-center mb-3">Create / Edit User</h2>
            </div>
          </div>
        </div>

        <div className="container contain">
          <input type="hidden" name="user_id" value={user_id} />

          <div className="row">
            <div className="col-md-4">Name :</div>
            <div className="col-md-8">
              <input
                type="text"
                name="username"
                value={username}
                onChange={(e) => this.handleChange(e)}
              />
            </div>
          </div>
          <br />

          <div className="row">
            <div className="col-md-4">Password :</div>
            <div className="col-md-8">
              <input
                type="password"
                name="password"
                value={password}
                onChange={(e) => this.handleChange(e)}
              />
            </div>
          </div>
          <br />

          <div className="row">
            <div className="col-md-4">Role :</div>
            <div className="col-md-8">
              <select
                name="role"
                id="role"
                onChange={(e) => this.handleChange(e)}
                value={role}
              >
                <option value="2">User</option>
                <option value="1">Admin</option>
              </select>
            </div>
          </div>
          <hr />

          <div className="row submit-section">
            <div className="col-md-12">
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => this.handleSubmit(e)}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.Users.data,
    user: state.User.data,
  };
};
const mapDispatchToProps = (dispatch) => ({
  GetUser: (userId) => {
    return new Promise((resolve) => {
      dispatch(GetUser(userId)).then(() => resolve());
    });
  },
  CreateUser: (params) => {
    dispatch(CreateUser(params));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AdminCreate);
