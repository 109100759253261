import { DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILED } from "./types";

import { deleteApi, emptyTrashApi } from "../api/delete";

import errorHandler from "../utils/errHandler";

export const DeleteActions = (params) => async (dispatch) => {
  dispatch(DeleteActionRequest());

  await deleteApi(params)
    .then((response) => {
      const { data } = response;

      if (data.status == "ok") {
        dispatch(DeleteActionSuccess(data));
      } else {
        dispatch(DeleteActionFailed(data));
      }
    })
    .catch((error) => {
      console.log("error : ", error);

      errorHandler(error);
    });
};

export const DeleteActionRequest = () => {
  return {
    type: DELETE_REQUEST,
  };
};

export const DeleteActionSuccess = (result) => {
  return {
    type: DELETE_SUCCESS,
    payload: {
      result,
    },
  };
};

export const DeleteActionFailed = (result) => {
  return {
    type: DELETE_FAILED,
    payload: {
      result,
    },
  };
};

export const EmptyTrash = () => () => {
  emptyTrashApi().then(() => {
    console.log("empty trash");
  });
};
