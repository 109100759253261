export const API_URL = "https://app.lpmlgroup.com/apps/api";

// export const API_URL = "http://127.0.0.1:8000/api";
export const LOGIN_URL = API_URL + "/login";
export const CATEGORIES_URL = API_URL + "/category";

export const UPLOAD_URL = API_URL + "/create";
export const QUERY_URL = API_URL + "/query";
export const DELETE_URL = API_URL + "/delete";

export const GET_USER_URL = API_URL + "/user";
export const GET_USERS_URL = API_URL + "/users";
export const CREATE_USER_URL = API_URL + "/user/create";
export const DELETE_USER_URL = API_URL + "/user/delete";
