import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import { arrs } from "../../utils/data";

class CategoryContainer extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleNavigation = this.handleNavigation.bind(this);
  }

  handleClick = (item) => {
    const { history } = this.props;

    history.push(`/upload/${item.id}`);
  };

  handleNavigation = (e) => {
    e.preventDefault();

    const { history } = this.props;

    history.push("/dashboard");
  };

  render() {
    const { date } = this.props;

    return (
      <>
        <div className="category-container">
          <div className="category-upper">
            <div
              className="category-navigation"
              onClick={(e) => this.handleNavigation(e)}
            >
              {"< "}Back
            </div>
            <h2>Select a Category</h2>
            <div>
              <a href="/dashboard" onClick={(e) => this.handleNavigation(e)}>
                {date}
              </a>
            </div>
          </div>

          <div className="category-wrapper">
            {arrs.map((group, index) => {
              return (
                <div key={index} className="category-section">
                  {group.map((item, i) => {
                    return (
                      <div key={i} className="category-group">
                        <div
                          className={`category-item ${
                            !item.name ? "no-border" : ""
                          }`}
                          onClick={() =>
                            item.name ? this.handleClick(item) : ""
                          }
                        >
                          {item.name}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  date: state.date.date,
});

const mapDispatchToProps = (dispatch) => ({
  SetDate: (value) => {
    dispatch(SetDate(value));
  },
  ResetDate: (value) => {
    dispatch(ResetDate(value));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CategoryContainer);
