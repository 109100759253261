import { SET_CURRENT_DATE, RESET_CURRENT_DATE } from "../actions/types";

export const SetDate = (value) => {
  return {
    type: SET_CURRENT_DATE,
    payload: {
      date: value,
    },
  };
};

export const ResetDate = () => {
  return {
    type: RESET_CURRENT_DATE,
  };
};
