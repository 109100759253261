import { SET_CURRENT_DATE, RESET_CURRENT_DATE } from "../actions/types";
import { formatDate } from "../utils/helpers";

let now = Date.now();
let today = formatDate(now);

const initialState = {
  date: today,
};

const DateTime = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_DATE:
      let { date } = action.payload;
      var newState = {
        date,
      };

      return { ...state, ...newState };

    case RESET_CURRENT_DATE:
      var newState = {
        date: today,
      };

      return { ...state, ...newState };

    default:
      return state;
  }
};

export default DateTime;
