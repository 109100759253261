import {
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAILED,
} from "../actions/types";

const initialState = {
  isLoading: false,
};

const Delete = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_REQUEST:
      var newState = {
        isLoading: true,
      };

      return { ...state, ...newState };

    case DELETE_SUCCESS:
      var newState = {
        isLoading: false,
      };

      return { ...state, ...newState };

    case DELETE_FAILED:
      var newState = {
        isLoading: false,
      };

      return { ...state, ...newState };

    default:
      return state;
  }
};

export default Delete;
