export const arrs = [
  [
    {
      id: 1,
      name: "Sales",
    },
    {
      id: 2,
      name: "Production",
    },
    {
      id: 3,
      name: "Purchasing",
    },
  ],
  [
    {
      id: 4,
      name: "Inventory",
    },
    {
      id: 5,
      name: "Fixed Asset",
    },
    {
      id: 6,
      name: "Finance",
    },
  ],
  [
    {
      id: 7,
      name: "Accounting",
    },
    {
      id: 8,
      name: "Budget",
    },
    {
      id: 9,
      name: "Master",
    },
  ],
];
