import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import { AuthLogin } from "../../../actions/auth";

const initialState = {
  username: "",
  password: "",
  error: "",
};

class LoginContainer extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.handleClear = this.handleClear.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.checkAuth = this.checkAuth.bind(this);
  }

  componentDidMount() {
    this.checkAuth();
  }

  checkAuth() {
    const { isAuth, history } = this.props;

    history.push(isAuth ? "/dashboard" : "/");
  }

  handleChange(e) {
    e.preventDefault();

    const target = e.target;
    const name = target.name;
    const value = target.value;

    this.setState({
      [name]: value,
      error: "",
    });
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    if (this.checkSubmit()) {
      const { username, password } = this.state;
      const { AuthLogin } = this.props;

      await AuthLogin(username, password).then(() => this.checkAuth());
    } else {
      this.setState({
        error: "Please insert username & Password",
      });
    }
  };

  handleClear = () => {
    this.setState(initialState);
  };

  checkSubmit = (result = false) => {
    const { username, password } = this.state;

    if ((username && password) != "") {
      result = true;
    }

    return result;
  };

  render() {
    const { username, password, error } = this.state;
    const hasError = error;
    const erroMessage = error;

    return (
      <div className="h-100 d-flex align-items-center justify-content-center">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 offset-lg-3">
              <h2 className="text-center mb-3">Furaidon App</h2>

              <div>
                <label className="form-label" htmlFor="username">
                  Username
                </label>

                <div className="input-group mb-3">
                  <input
                    className="form-control"
                    name="username"
                    type="text"
                    placeholder="Insert your username"
                    onChange={(e) => this.handleChange(e)}
                    value={username}
                  />
                </div>

                <label className="form-label" htmlFor="password">
                  Password
                </label>

                <div className="input-group mb-3">
                  <input
                    className="form-control"
                    name="password"
                    type="password"
                    placeholder="Insert your password"
                    onChange={(e) => this.handleChange(e)}
                    value={password}
                  />
                </div>

                {hasError && (
                  <div className="message-section-wrapper mb-3">
                    <div className="message-section text-danger">
                      {erroMessage}
                    </div>
                  </div>
                )}

                <div className="button-group">
                  <button
                    className="btn btn-info"
                    type="button"
                    onClick={(e) => this.handleSubmit(e)}
                  >
                    Submit
                  </button>{" "}
                  <button
                    className="btn btn-warning"
                    type="button"
                    onClick={() => this.handleClear()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuth: state.auth.isAuth,
  };
};

const mapDispatchToProps = (dispatch) => ({
  AuthLogin: (username, password) => {
    return new Promise((resolve) => {
      dispatch(AuthLogin(username, password)).then(() => resolve());
    });
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(LoginContainer);
