import { combineReducers } from "redux";

import auth from "./auth";
import date from "./date";
import Delete from "./delete";
import upload from "./upload";
import search from "./search";
import Users from "./users";
import User from "./user";
import SubCategories from "./SubCategories";

const rootReducer = combineReducers({
  auth,
  date,
  upload,
  Delete,
  search,
  User,
  Users,
  SubCategories,
});

export default rootReducer;
